var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "modal fade",
    attrs: {
      "id": "senderID",
      "tabindex": "-1",
      "role": "dialog",
      "aria-labelledby": "contact",
      "aria-hidden": "true"
    }
  }, [_c('div', {
    staticClass: "modal-dialog modal-dialog-centered",
    attrs: {
      "role": "document"
    }
  }, [_vm.success ? _c('div', {
    staticClass: "modal-content d-flex align-items-center"
  }, [_c('successMessage', {
    attrs: {
      "message": _vm.success
    }
  }), _c('button', {
    staticClass: "__close__success__modal",
    on: {
      "click": _vm.hideModal
    }
  }, [_vm._v("Close")])], 1) : _vm._e(), !_vm.success && _vm.senderModalState.type == 'delete' ? _c('div', {
    staticClass: "modal-content"
  }, [_c('div', {
    staticClass: "modal-body m-0 p-0"
  }, [_c('div', {
    staticClass: "row justify-content-center align-items-center mx-4"
  }, [_c('div', {
    staticClass: "continue_edit_header pb-2"
  }, [_vm._m(0), _c('h3', {
    staticClass: "continue_edit_header_title"
  }, [_vm._v(" " + _vm._s(_vm.senderModalState.title) + " ")])])]), _vm.errors ? _c('div', {
    staticClass: "continue_edit_header"
  }, [_c('Alert', {
    attrs: {
      "message": _vm.errors,
      "type": _vm.type
    }
  })], 1) : _vm._e(), _c('div', {
    staticClass: "modal__footer__btns",
    staticStyle: {
      "margin-left": "5em",
      "margin-right": "4em"
    }
  }, [_c('el-button', {
    staticStyle: {
      "margin-right": "1.5em",
      "padding-right": "2em",
      "padding-left": "2em"
    },
    attrs: {
      "loading": _vm.loading,
      "type": "danger"
    },
    on: {
      "click": _vm.deleteSenderID
    }
  }, [_vm._v(" Delete ")]), _c('el-button', {
    staticStyle: {
      "margin-right": "1.5em",
      "padding-right": "2em",
      "padding-left": "2em"
    },
    attrs: {
      "type": "primary"
    },
    on: {
      "click": _vm.hideModal
    }
  }, [_vm._v(" Cancel ")])], 1)])]) : _vm._e(), !_vm.success && _vm.senderModalState.type !== 'delete' ? _c('div', {
    staticClass: "modal-content"
  }, [_c('div', {
    staticClass: "modal-header pt-4 pb-2"
  }, [_c('h5', {
    staticClass: "modal-title",
    attrs: {
      "id": "exampleModalLabel"
    }
  }, [_vm.senderModalState.birthdayType == 'sms' ? _c('p', {
    staticClass: "_set_default"
  }, [_vm._v("Create a birthday message ")]) : _c('p', {
    staticClass: "_set_default"
  }, [_vm._v("Edit birthday message")])])]), _c('div', {
    staticClass: "modal-body m-0 p-0"
  }, [_c('div', {
    staticClass: "row justify-content-center",
    staticStyle: {
      "padding-right": "1em",
      "padding-left": "1em"
    }
  }, [_vm.birthdayType == 'sms' ? _c('div', {
    staticClass: "col-md-12"
  }, [_c('el-form', {
    ref: "ruleForm",
    attrs: {
      "model": _vm.formData,
      "rules": _vm.rules
    },
    nativeOn: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.checkEnter.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "row justify-content-center"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "form_input_container"
  }, [_c('el-form-item', {
    attrs: {
      "label": "Title",
      "prop": "title"
    }
  }, [_c('el-input', {
    attrs: {
      "type": "text",
      "autocomplete": "off",
      "placeholder": "Enter the title of the message"
    },
    model: {
      value: _vm.formData.title,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "title", $$v);
      },
      expression: "formData.title"
    }
  })], 1)], 1)]), _c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "form_input_container"
  }, [_c('el-form-item', {
    staticStyle: {
      "margin-top": "0px"
    },
    attrs: {
      "label": "Message",
      "prop": "body"
    }
  }, [_c('el-input', {
    ref: "message",
    attrs: {
      "type": "textarea",
      "rows": 7,
      "autocomplete": "off",
      "placeholder": "Enter the message content"
    },
    model: {
      value: _vm.formData.body,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "body", $$v);
      },
      expression: "formData.body"
    }
  })], 1)], 1)])]), _vm.errors ? _c('span', [_c('Alert', {
    attrs: {
      "message": _vm.errors,
      "type": _vm.type
    }
  })], 1) : _vm._e()])], 1) : _vm._e()]), _vm.senderModalState.type == 'newSenderID' ? _c('div', {
    staticClass: "modal__footer__btns"
  }, [_c('button', {
    staticClass: "cancel",
    on: {
      "click": _vm.hideModal
    }
  }, [_vm._v("Cancel")]), _vm.senderModalState.birthdayType == 'sms' ? _c('el-button', {
    attrs: {
      "loading": _vm.loading,
      "type": "primary",
      "id": "__create__btn"
    },
    on: {
      "click": function ($event) {
        return _vm.submit('ruleForm', 'save');
      }
    }
  }, [_vm._v(" Save ")]) : _vm._e(), _vm.senderModalState.birthdayType == 'edit' ? _c('el-button', {
    attrs: {
      "loading": _vm.loading,
      "type": "primary",
      "id": "__create__btn"
    },
    on: {
      "click": function ($event) {
        return _vm.submit('ruleForm', 'edit');
      }
    }
  }, [_vm._v(" Edit ")]) : _vm._e()], 1) : _vm._e()])]) : _vm._e()])]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "_edit_icon text-center"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/Delete.svg"),
      "height": "33px",
      "width": "31px",
      "alt": "bin icon for delete"
    }
  })]);
}];
export { render, staticRenderFns };