import $ from 'jquery';
import store from '@/state/store.js';
import BirthdayModal from './birthdayModal';
export default {
  name: 'sms-birthday',
  components: {
    BirthdayModal: BirthdayModal
  },
  data() {
    return {
      messages: [],
      loading: false,
      userData: {}
    };
  },
  computed: {
    birthdayMessages() {
      return store.getters['campaigns/birthdayMessages'];
    }
  },
  mounted() {
    this.fetchBirthDays();
  },
  methods: {
    editSMS(message, key) {
      store.dispatch('senderid/setSenderModalState', {
        title: 'Update birthday message',
        type: "newSenderID",
        birthdayType: 'edit',
        message: message
      });
      this.userData = {
        message: message,
        key: key
      };
      this.showModal();
    },
    success() {
      this.$forceUpdate();
    },
    showModal() {
      let element = this.$refs.birthday.$el;
      $(element).modal('show');
    },
    deleteSMS(message, key) {
      store.dispatch('senderid/setSenderModalState', {
        title: "Delete Birthday SMS will remove it permanently",
        type: 'delete'
      });
      this.userData = {
        message: message,
        key: key
      };
      this.showModal();
    },
    async fetchBirthDays() {
      try {
        this.loading = true;
        const {
          data
        } = await store.dispatch('campaigns/fetchTemplate');
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
    openBirthDayModal() {
      store.dispatch('senderid/setSenderModalState', {
        title: "Register a new sender ID",
        type: 'newSenderID',
        btnTitle: "Register new send ID",
        birthdayType: 'sms'
      });
      let element = this.$refs.birthday.$el;
      this.userData = {
        message: null,
        key: null
      };
      $(element).modal('show');
    }
  }
};