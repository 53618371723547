import $ from 'jquery';
import MessageModal from '../messages/messageModal.vue';
import store from '@/state/store.js';
export default {
  name: 'birthday-voice',
  components: {
    MessageModal: MessageModal
  },
  data() {
    return {
      dataPayload: {},
      loading: false,
      messages: []
    };
  },
  mounted() {
    this.fetchBirthDays();
  },
  methods: {
    showModal() {
      let element = this.$refs.messageModal.$el;
      $(element).modal('show');
    },
    deleteSMS(sms, key) {
      this.dataPayload = {
        currentModal: 'delete',
        selectedKey: key,
        deleteType: 'voice',
        selectedMessage: sms,
        deleteMessage: 'Deleting Birthday Voice Message will remove it permenantly.'
      };
      store.dispatch('uistate/addContactFormData', {
        title: "",
        type: 'delete'
      });
      this.showModal();
    },
    async fetchBirthDays() {
      //store.dispatch('campaigns/fetchBirthdayTemplate')
      try {
        this.loading = true;
        const {
          data
        } = await store.dispatch('voice/getVoiceTemplates');
        this.loading = false;
        this.messages = data;
      } catch (error) {
        this.loading = false;
      }
    },
    saveVoiceMessage() {
      // fetch voice templates then emit
      this.fetchBirthDays();
      this.$emit('refreshTemplates');
    },
    openModal() {
      store.dispatch('uistate/addContactFormData', {
        title: "Create a birthday voice message",
        type: 'voiceTemplate',
        isBirthDay: true
      });
      this.showModal();
    }
  }
};