import store from '@/state/store.js';
let $ = require('jquery');
export default {
  name: 'create-birth-messag-and-voice',
  props: {
    userData: {
      required: false
    }
  },
  data() {
    return {
      success: '',
      errors: "",
      loading: false,
      type: 'alert-danger',
      selectSenderID: false,
      formData: {
        title: '',
        body: '',
        type: 3,
        id: '',
        key: ''
      },
      rules: {
        title: [{
          required: true,
          message: 'Please message title is required.',
          trigger: 'change'
        }],
        body: [{
          required: true,
          message: 'Please message body is required',
          trigger: 'change'
        }]
      },
      birthdayType: 'sms'
    };
  },
  created() {},
  watch: {
    userData: function (value) {
      this.formData.title = value.message.title;
      this.formData.body = value.message.body;
      this.formData.id = value.id;
    }
  },
  computed: {
    senderModalState() {
      return store.getters['senderid/senderModalState'];
    }
  },
  methods: {
    handBodyChange(value) {
      this.formData.body = value;
    },
    handTitleChange() {
      this.formData.title = value;
    },
    hideModal() {
      $('#senderID').modal('hide');
      if (this.success) {
        this.$emit('success');
      }
      this.success = "";
      this.loading = false;
      this.errors = "";
    },
    deleteSenderID() {
      this.errors = '';
      this.loading = true;
      this.success = "";
      const {
        id,
        key
      } = this.userData.message;
      store.dispatch('campaigns/deleteTemplate', {
        id: id,
        key: key
      }).then(response => {
        this.loading = false;
        this.success = response.data.message;
        this.$emit("fetchTemplatesAgain");
      }).catch(err => {
        var _err$response;
        this.loading = false;
        if (((_err$response = err.response) === null || _err$response === void 0 ? void 0 : _err$response.data) == undefined) {
          this.errors = 'Network error try again';
        }
        if (err.response && err.response.status === 404) {
          this.errors = err.response.data.data.error;
        }
      });
    },
    createSMS() {
      this.errors = '';
      this.loading = true;
      this.formData.type = 3;
      store.dispatch('campaigns/saveTemplate', this.formData).then(response => {
        if (response) {
          this.loading = false;
          this.success = "Birthday messsage created successfully";
          this.formData = {
            title: '',
            body: ''
          };
          this.$emit("fetchTemplatesAgain");
        }
      }).catch(err => {
        var _err$response2;
        this.loading = false;
        if (((_err$response2 = err.response) === null || _err$response2 === void 0 ? void 0 : _err$response2.data) == undefined) {
          this.errors = 'Network error try again';
        }
        if (err.response && err.response.status === 404) {
          if (err.response.data.data.sender_id) {
            return this.errors = err.response.data.data.sender_id[0];
          }
          this.errors = err.response.data.data.error;
        }
      });
    },
    editSMS() {
      this.errors = '';
      this.loading = true;
      this.formData.id = this.userData.message.id;
      this.formData.key = this.userData.key;
      this.formData.type = 3;
      store.dispatch('campaigns/editTemplate', {
        body: this.formData,
        key: this.formData.key
      }).then(response => {
        if (response) {
          this.loading = false;
          this.success = "Birthday messsage Updated successfully";
          this.formData = {
            title: '',
            body: ''
          };
          this.$emit("fetchTemplatesAgain");
        }
      }).catch(err => {
        var _err$response3;
        this.loading = false;
        if (((_err$response3 = err.response) === null || _err$response3 === void 0 ? void 0 : _err$response3.data) == undefined) {
          this.errors = 'Network error try again';
        }
        if (err.response && err.response.status === 404) {
          if (err.response.data.data.sender_id) {
            return this.errors = err.response.data.data.sender_id[0];
          }
          this.errors = err.response.data.data.error;
        } else {
          this.$notify({
            title: 'Error',
            message: err.response.data.message,
            type: 'error'
          });
        }
      });
    },
    submit(formName, type) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          if (type == 'save') {
            return this.createSMS();
          }
          if (type == 'edit') {
            return this.editSMS();
          }
        } else {
          return false;
        }
      });
    }
  }
};