import { render, staticRenderFns } from "./smsmessages.vue?vue&type=template&id=4c9b3789&scoped=true"
import script from "./smsmessages.vue?vue&type=script&lang=js"
export * from "./smsmessages.vue?vue&type=script&lang=js"
import style0 from "./smsmessages.vue?vue&type=style&index=0&id=4c9b3789&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c9b3789",
  null
  
)

export default component.exports